import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TIntialState = {
  isPreviewVisible: boolean;
  logoUrl: string | undefined;
  colorCode: string | undefined;
  buttonPrimaryColor: string | undefined;
  buttonSecondaryColor: string | undefined;
  gradientForegroundColor: string | undefined;
  appliedTheme: {
    logoUrl: string | undefined;
    colorCode: string | undefined;
    buttonPrimaryColor: string | undefined;
    buttonSecondaryColor: string | undefined;
    gradientForegroundColor: string | undefined;
    CertificateSignatureImage: string | undefined;
    CertificateSignerName: string | undefined;
    CertificateSignerDesignation: string | undefined;
  };
  CertificateSignatureImage: string | undefined;
  CertificateSignerName: string | undefined;
  CertificateSignerDesignation: string | undefined;
};

const initialState: TIntialState = {
  isPreviewVisible: false,
  logoUrl: undefined,
  colorCode: undefined,
  buttonPrimaryColor: undefined,
  buttonSecondaryColor: undefined,
  gradientForegroundColor: undefined,
  appliedTheme: {
    logoUrl: undefined,
    colorCode: undefined,
    buttonPrimaryColor: undefined,
    buttonSecondaryColor: undefined,
    gradientForegroundColor: undefined,
    CertificateSignatureImage: undefined,
    CertificateSignerName: undefined,
    CertificateSignerDesignation: undefined,
  },
  CertificateSignatureImage: undefined,
  CertificateSignerName: undefined,
  CertificateSignerDesignation: undefined,
};

const ThemeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setLogo: (state, action: PayloadAction<string | undefined>) => {
      state.logoUrl = action.payload;
    },
    setCertificateSignatureImage: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.CertificateSignatureImage = action.payload;
    },
    setCertificateSignerName: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.CertificateSignerName = action.payload;
    },
    setCertificateSignerDesignation: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.CertificateSignerDesignation = action.payload;
    },
    setColor: (state, action: PayloadAction<string | undefined>) => {
      state.colorCode = action.payload;
    },
    setButtonPrimaryColor: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.buttonPrimaryColor = action.payload;
    },
    setButtonSecondaryColor: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.buttonSecondaryColor = action.payload;
    },
    setGradientFGColor: (state, action: PayloadAction<string | undefined>) => {
      state.gradientForegroundColor = action.payload;
    },
    setPreviewVisible: (state, action: PayloadAction<boolean>) => {
      state.isPreviewVisible = action.payload;
    },
    resetTheme: (state) => {
      return initialState;
    },
    applyTheme: (
      state,
      action: PayloadAction<TIntialState["appliedTheme"]>
    ) => {
      state.appliedTheme = { ...state.appliedTheme, ...action.payload };
    },
  },
});

export const {
  setLogo,
  setCertificateSignatureImage,
  setCertificateSignerName,
  setCertificateSignerDesignation,
  setColor,
  setButtonPrimaryColor,
  setButtonSecondaryColor,
  setGradientFGColor,
  setPreviewVisible,
  resetTheme,
  applyTheme,
} = ThemeSlice.actions;

export default ThemeSlice.reducer;
