import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DEFAULT_CHECKED,
  WorkForceTableDataType,
} from "../../modules/features/WorkforceProfile/helper/constant";
import { FreezeEnum } from "../../modules/features/Teams/helper/constant";

interface WorkforceProfileTableSlice {
  visibleColumns: Partial<keyof WorkForceTableDataType>[];
  selectedFreezed: number;
}

const initialState: WorkforceProfileTableSlice = {
  visibleColumns: DEFAULT_CHECKED,
  selectedFreezed: FreezeEnum.FreezePanes,
};

export const workForceProfileTableSlice = createSlice({
  name: "workForceProfileTable",
  initialState,
  reducers: {
    setWorkforceProfileTableState: (
      state,
      action: PayloadAction<Pick<WorkforceProfileTableSlice, "visibleColumns">>
    ) => {
      state.visibleColumns = action.payload.visibleColumns;
    },
    setWorkforceProfileFreezeState: (
      state,
      action: PayloadAction<Pick<WorkforceProfileTableSlice, "selectedFreezed">>
    ) => {
      state.selectedFreezed = action.payload.selectedFreezed;
    },
  },
});

export const { setWorkforceProfileTableState, setWorkforceProfileFreezeState } =
  workForceProfileTableSlice.actions;

export default workForceProfileTableSlice.reducer;
