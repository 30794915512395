import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { globalInitialState } from "../../modules/core/models/types/global.types";
import { NOTIFICATION_CONST } from "../../modules/core/models/constants/core.constants";

const initialState: globalInitialState = {
  error: {
    message: "",
    type: NOTIFICATION_CONST.ERROR,
  },
  networkError: {
    message: "",
    type: NOTIFICATION_CONST.ERROR,
  },
  loading: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setGlobalState: (state, action: PayloadAction<globalInitialState>) => {
      state.error = {
        ...state.error,
        ...(action.payload.error || {
          message: "",
          type: NOTIFICATION_CONST.ERROR,
        }),
      };

      state.networkError = {
        ...state.networkError,
        ...(action.payload.networkError || {
          message: "",
          type: NOTIFICATION_CONST.ERROR,
        }),
      };

      state.loading = action?.payload?.loading || false;
    },
  },
});

export const { setGlobalState } = globalSlice.actions;

export default globalSlice.reducer;
