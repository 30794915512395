import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssessmentQuestionResponseModal } from "../../modules/features/Academy/services/academyAssessment.service";

interface AcademyAssessmentData {
  academySubmoduleKey: string;
  numberOfQuestions: string;
  languageId: string;
  isAssessmentSubmitted: boolean;
  data: AssessmentQuestionResponseModal | undefined;
}

export const initialAcademyAssessmentState: AcademyAssessmentData = {
  academySubmoduleKey: "",
  numberOfQuestions: "",
  languageId: "",
  isAssessmentSubmitted: false,
  data: undefined,
};

export const academyAssessmentSlice = createSlice({
  name: "academyAssessment",
  initialState: initialAcademyAssessmentState,
  reducers: {
    setAcademyAssessmentState: (
      state,
      action: PayloadAction<AcademyAssessmentData>
    ) => {
      state.academySubmoduleKey = action.payload.academySubmoduleKey;
      state.numberOfQuestions = action.payload.numberOfQuestions;
      state.languageId = action.payload.languageId;
      state.isAssessmentSubmitted = action.payload.isAssessmentSubmitted;
      state.data = action.payload.data;
    },
  },
});

export const { setAcademyAssessmentState } = academyAssessmentSlice.actions;
export default academyAssessmentSlice.reducer;
