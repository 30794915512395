import { TablePaginationConfig, TableProps } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { GetProp } from "antd/lib";
import { IndividualEmployeeAddFormType } from "../model";

export const FieldValidations = {
  NAME_MIN: 2,
  NAME_MAX: 20,
  SURNAME_MIN: 2,
  SURNAME_MAX: 20,
  EMPLOYEE_NUMBER_MIN: 1,
  EMPLOYEE_NUMBER_MAX: 20,
  EMAIL_MIN: 6,
  EMAIL_MAX: 60,
  MOBILE_MIN: 10,
  MOBILE_MAX: 20,
  ID_NUMBER_MIN: 13,
  ID_NUMBER_MAX: 13,
  PASSPORT_MIN: 5,
  AGE_MIN: 1,
  AGE_MAX: 2,
  BRANCH_MIN: 2,
  BRANCH_MAX: 30,
  BUSINESS_UNIT_MIN: 2,
  BUSINESS_UNIT_MAX: 30,
  DEPARTMENT_MIN: 2,
  DEPARTMENT_MAX: 30,
  JOB_TITLE_MIN: 2,
  JOB_TITLE_MAX: 30,
  OFO_CODE_MIN: 2,
  OFO_CODE_MAX: 10,
};

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const WorkForceProfileTableFilterFields: Partial<
  Record<keyof WorkForceTableDataType, string>
> = {
  employeeFirstName: "Employee First Name",
  employeeSurname: "Employee Surname",
  employeeNumber: "Employee Number",
  emailAddress: "Email Address",
  idNumber: "ID Number",
  passportNumber: "Passport number",
  foreignNational: "Foreign National",
  streetAddress: "Street Address",
  suburb: "Suburb",
  city: "City",
  provinceId: "Province/State",
  countryName: "Country",
  postalCode: "Postal Code",
  branch: "Branch",
  department: "Department",
  businessUnit: "Business Unit",
  jobTitle: "Job Title",
  employmentStatus: "Employment Status",
  userType: "User Type",
  accessType: "Access Type",
};

export const WORKFORCE_TABLE_FILTER_LOCALSTORAGE_KEY =
  "TAP_WORKFORCE_TABLE_FILTER";

export const DEFAULT_CHECKED: Partial<keyof WorkForceTableDataType>[] = [
  "employeeFirstName",
  "employeeSurname",
  "emailAddress",
  "employeeNumber",
];

export const CSVMappingOfIDFields: Partial<
  Record<keyof WorkForceTableDataType, keyof WorkForceTableDataType>
> = {
  employmentStatus: "employmentStatusName",
  userType: "userTypeName",
};

export type SORT_ORDER = "ascend" | "descend";
export const TABLE_SORT_ENUM: Record<SORT_ORDER, SORT_ORDER> = {
  ascend: "ascend",
  descend: "descend",
};

export interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: SorterResult<WorkForceTableDataType>["field"];
  sortOrder?: SorterResult<WorkForceTableDataType>["order"];
  filters?: Parameters<GetProp<TableProps, "onChange">>[1];
}

export const WFP_RECORDS_PER_PAGE = 10;

export const TEXT_INPUT_REG = new RegExp(/^[a-zA-Z0-9_ ]*$/);

// As requested by xander on 3 dec 2024 in DSM, we are disabling user action for certain time period (indefinitely)
export const DISABLE_BUTTON_TIMEOUT = 30000;

export type WorkForceTableDataType = IndividualEmployeeAddFormType & {
  key: string;
  countryName: string;
  employmentStatusName: string;
  userTypeName: string;
  workforceProfileId: number;
  isWhatsAppUser: boolean;
};
