import { createContext, useCallback, useContext, useMemo } from "react";
import { ComponentProps } from "../models/interfaces/component";
import { ApiOperations, useAPIService } from "./API.service";
import { API_URLS } from "../models/enums/url.enums";

const { REACT_APP_API_URL } = process.env;

export interface UploadServiceOperations {
  fileUpload: Function;
  getFileList: Function;
  getFile: Function;
  deleteFile: Function;
}
const UploadServiceContext = createContext<UploadServiceOperations | null>(
  null
);

export const UploadServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();

  // Common API A
  const fileUpload = useCallback((body: any) => {
    return APIService?.post(REACT_APP_API_URL + API_URLS.BASE_DOCUMENT, body);
  }, [APIService]);

  const getFileList = useCallback((body: any) => {
    //return APIService?.post(REACT_APP_API_URL + API_URLS.BASE_USER+API_URLS.LOGIN,body);
  }, []);

  const getFile = useCallback((body: any) => {
    //return APIService?.post(REACT_APP_API_URL + API_URLS.BASE_USER+API_URLS.LOGIN,body);
  }, []);

  const deleteFile = useCallback((body: any) => {
    //return APIService?.post(REACT_APP_API_URL + API_URLS.BASE_USER+API_URLS.LOGIN,body);
  }, []);

  const UploadServiceOperations: UploadServiceOperations = useMemo(
    () => ({
      fileUpload,
      getFileList,
      getFile,
      deleteFile,
    }),
    [fileUpload, getFileList, getFile, deleteFile]
  );

  return (
    <UploadServiceContext.Provider value={UploadServiceOperations}>
      {props.children}
    </UploadServiceContext.Provider>
  );
};

export const useUploadService = () => {
  return useContext(UploadServiceContext);
};
