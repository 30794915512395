import axios, { AxiosError } from "axios";
import { API_URLS, HTTP_STATUS_CODES } from "../models/enums/url.enums";
import JWTDecode from "../helper/jwt-decode";
import { store } from "../../../store";
import { authInitialState, setAuthState } from "../../../store/slice/AuthSlice";
import {
  setUserDetailState,
  userDetailInitialState,
} from "../../../store/slice/UserDetailSlice";
import {
  setUserSignupDetailState,
  userSignupDetailInitialState,
} from "../../../store/slice/UserSignupDetailSlice";
import {
  setCartlistCountState,
  setWishlistCountState,
} from "../../../store/slice/CartDetails";
import { QueryClient } from "@tanstack/react-query";
import { setGlobalState } from "../../../store/slice/GlobalSlice";
import { NOTIFICATION_CONST } from "../models/constants/core.constants";
const { REACT_APP_API_URL } = process.env;
const arrayOfAPIsWithTimeout: Array<string> = [];
let isAlreadyFetchingAccessToken = false;

const axiosHttp = axios.create({
  baseURL: `${REACT_APP_API_URL}`,
});

const urlsToIgnoreJwt = () => {
  return "/termsandcondition|/login|/logout|/signup|/register|/SendOtp|/forgetpassword|/changepassword|/validateOtp|/ValidateRegister|/resetPassword|/refreshtoken";
};

const sessionExpireHandler = () => {
  store.dispatch(
    setGlobalState({
      error: {
        message: "Your Session is expired you will be logged out.",
        type: NOTIFICATION_CONST.ERROR,
      },
    })
  );
  setTimeout(() => {
    sessionTimeOutHandler();
  }, 3000);
};

export const sessionTimeOutHandler = () => {
  store.dispatch(setAuthState(authInitialState));
  store.dispatch(setUserDetailState(userDetailInitialState));
  store.dispatch(setUserSignupDetailState(userSignupDetailInitialState));
  store.dispatch(setCartlistCountState(0));
  store.dispatch(setWishlistCountState(0));
  const queryClient = new QueryClient();
  queryClient.removeQueries({ queryKey: ["KEY"] });
  let buildversion: string | null = localStorage.getItem("version");
  localStorage.clear();
  buildversion && localStorage.setItem("version", buildversion);
};

const getSlugHandler = (url: string) => {
  let pathSegments = url.split("/");
  let lastSlug = pathSegments[pathSegments?.length - 1];
  let slugWithoutQueryParam = lastSlug.split("?");
  return slugWithoutQueryParam[0];
};

const setToken = (config: any) => {
  config.metadata = { startTime: new Date() };
  const state = store.getState();
  const token = state.auth.token;

  if (token) {
    if (
      config.url === REACT_APP_API_URL + API_URLS.BASE_DOCUMENT ||
      config.url ===
        REACT_APP_API_URL + API_URLS.BASE_BROADCASTS + API_URLS.CREATE ||
      config.url ===
        REACT_APP_API_URL + API_URLS.BASE_BROADCASTS + API_URLS.UPDATE ||
      config.url ===
        REACT_APP_API_URL + API_URLS.BASE_BANNERS + API_URLS.CREATE ||
      config.url === REACT_APP_API_URL + API_URLS.BASE_BANNERS + API_URLS.UPDATE
    ) {
      config.headers = {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      };
    } else {
      config.headers = {
        Authorization: "Bearer " + token,
      };
    }
  }

  return config;
};

const renewToken = (originalRequest: any) => {
  const state = store.getState();
  const auth = state.auth;
  const token = auth.token;
  const refreshToken = auth.refreshToken;

  return axiosHttp
    .post(REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.HAND_SHAKE, {
      accessToken: token,
      refreshToken: refreshToken,
    })
    .then((response: any) => {
      if (response?.data?.isError && response?.data?.statusCode === 400) {
        isAlreadyFetchingAccessToken = false;
        return false;
      } else {
        isAlreadyFetchingAccessToken = false;
        store.dispatch(
          setAuthState({
            ...auth,
            token: response?.data?.result?.token,
          })
        );
        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: response?.config?.headers?.Authorization,
        };
        return originalRequest;
      }
    })
    .catch(function (e) {
      sessionExpireHandler();
      isAlreadyFetchingAccessToken = false;
      return false;
    });
};

const checkNetworkTimeError = (response: any) => {
  const elapsedTime: number =
    new Date().getTime() - response.config.metadata.startTime.getTime();
  if (elapsedTime > 20000) {
    const slugWithoutQueryParam = getSlugHandler(response.config.url);

    if (arrayOfAPIsWithTimeout.includes(slugWithoutQueryParam) === false) {
      arrayOfAPIsWithTimeout.push(slugWithoutQueryParam);
      store.dispatch(
        setGlobalState({
          networkError: {
            message: `We've detected a slow connection, this may result in longer loading times.`,
            type: NOTIFICATION_CONST.NETWORK_ERROR,
          },
        })
      );
    }
  }
};

axiosHttp.interceptors.request.use(async (config: any) => {
  const state = store.getState();
  const auth = state.auth;
  const token = auth.token;

  const slugWithoutQueryParam = getSlugHandler(config.url);
  const index = arrayOfAPIsWithTimeout.indexOf(slugWithoutQueryParam);
  if (index > -1) {
    arrayOfAPIsWithTimeout.splice(index, 1);
  }
  //To ignore non-authorize request API urls
  if (config.url.search(urlsToIgnoreJwt()) > 0) {
    return setToken(config);
  }
  let getSessionExpiresEpoch = JWTDecode(token)?.exp;
  getSessionExpiresEpoch = getSessionExpiresEpoch ?? 0;
  if (getSessionExpiresEpoch > 0) {
    const currentEpoch = Math.floor(new Date().getTime() / 1000);
    const activeSessionTimeDiff = getSessionExpiresEpoch - currentEpoch;

    if (activeSessionTimeDiff <= 0 && !isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const renewTokenconfig = await renewToken(config);
      if (!renewTokenconfig) {
        setToken(config);
        sessionExpireHandler();
      } else {
        setToken(renewTokenconfig);
      }
    }
  }
  return setToken(config);
});

axiosHttp.interceptors.response.use(
  async (response: any) => {
    checkNetworkTimeError(response);
    return response;
  },
  async (error) => {
    const status = error.response ? error.response.status : null;
    let originalRequest = error.config;
    if (status === HTTP_STATUS_CODES.UNAUTHORIZED) {
      originalRequest = await renewToken(originalRequest);
      return axios(originalRequest);
    } else if (error.response) {
      return Promise.reject(error as AxiosError);
    } else {
      error.response = {
        data: {
          errorMessage: error.message,
          isError: true,
        },
      };
      return Promise.reject(error as AxiosError);
    }
  }
);

export default axiosHttp;
