import { createContext, useCallback, useContext, useMemo } from "react";
import { ComponentProps } from "../../core/models/interfaces/component";
import { ApiOperations, useAPIService } from "../../core/services/API.service";
import { API_URLS } from "../../core/models/enums/url.enums";
import {
  CorporateUserType,
  UserDetailInitialState,
} from "../../core/models/types/userDetail.types";
import { useDispatch } from "react-redux";
import { setAuthState } from "../../../store/slice/AuthSlice";
import { QueryClient } from "@tanstack/react-query";

export type registerPayLoad = {
  FirstName: string;
  LastName: string;
  Email: string;
  Password: string;
  ContactNo: string;
  Address: string;
  IdNumber: string;
  Nationality: string;
  ClientKey: string;
  MarketingSource: string;
  UserType: string;
  //,"Role": "1"
};
export type SignupPayLoad = {
  selectedPlan: string;
  termsAndConditionIsChecked: string;
  UserDetailInitialState: UserDetailInitialState;
  otp?: string;
};
export type OTPPayLoad = {
  OTP: string;
  Email: string;
};
export type reactivatePayLoad = {
  token: string;
};
export type LoginPayLoad = {
  Email: string;
  Password: string;
};

const { REACT_APP_API_URL } = process.env;

export interface AuthServiceOperations {
  logIn: Function;
  reactivate: Function;
  saveLoginDetailsInStore: Function;
  resetPassword: Function;
  forgetPassword: Function;
  changePassword: Function;
  validateToken: Function;
  createPasswordOtp: Function;
  createPassword: Function;
  validateOtp: Function;
  signUp: Function;
  validateSingupForm: Function;
  sendOtp: Function;
  logout: Function;
  GetHearAboutUsList: Function;
  getCountryList: Function;
  corporateUserSignUp: Function;
  validateBusinessUserInquaryForm: Function;
}
const AuthServiceContext = createContext<AuthServiceOperations | null>(null);

export const AuthServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();
  const dispatch = useDispatch();

  const logIn = useCallback(
    (body: LoginPayLoad) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.LOGIN,
        body
      );
    },
    [APIService]
  );

  /**
   * Saves the login details in the store. without isLoggedIn.
   * isLoggedIn = false as the user is not logged in need other API's.
   *
   * @param {any} loginDetails - The login details to be saved.
   * @return {void} This function does not return anything.
   */
  const saveLoginDetailsInStore = useCallback(
    (loginDetails: any) => {
      dispatch(
        setAuthState({
          refreshToken: loginDetails.refreshToken,
          token: loginDetails.token,
          isLoggedIn: true,
          isAlreadyLoggedIn: loginDetails.isAlreadyLoggedIn,
        })
      );
    },
    [dispatch]
  );

  const resetPassword = useCallback(() => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.RESET_PASSWORD
    );
  }, [APIService]);

  const forgetPassword = useCallback(
    (email: string) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.FORGETPASSWORD,
        { Email: email }
      );
    },
    [APIService]
  );

  const changePassword = useCallback(
    (body: any) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.CHANGEPASSWORD,
        body
      );
    },
    [APIService]
  );

  const validateToken = useCallback(
    (body: any) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.VALIDATE_TOKEN,
        body
      );
    },
    [APIService]
  );

  const createPasswordOtp = useCallback(
    (email: string) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.CREATE_PASSWORD_OTP,
        { Email: email }
      );
    },
    [APIService]
  );

  const createPassword = useCallback(
    (body: any) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.CREATE_PASSWORD,
        body
      );
    },
    [APIService]
  );

  const signUp = useCallback(
    (body: SignupPayLoad) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.REGISTER,
        body
      );
    },
    [APIService]
  );

  const corporateUserSignUp = useCallback(
    (body: CorporateUserType) => {
      return APIService?.post(
        REACT_APP_API_URL +
          API_URLS.BASE_CLIENT +
          API_URLS.CORPORATE_USER_INQUIRY,
        body
      );
    },
    [APIService]
  );

  const validateSingupForm = useCallback(
    (body: UserDetailInitialState) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.VALIDATE_SIGNUP_FORM,
        body
      );
    },
    [APIService]
  );

  const sendOtp = useCallback(
    (body: string) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.SENDOTP,
        { Email: body }
      );
    },
    [APIService]
  );

  const validateOtp = useCallback(
    (body: OTPPayLoad) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.VALIDATEOTP,
        body
      );
    },
    [APIService]
  );

  const reactivate = useCallback(
    (body: reactivatePayLoad) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.REACTIVATE,
        body
      );
    },
    [APIService]
  );

  const logout = useCallback(() => {
    const queryClient = new QueryClient();
    queryClient.removeQueries({ queryKey: ["KEY"] });
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.LOGOUT
    );
  }, [APIService]);

  const GetHearAboutUsList = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.GET_HEAR_ABOUT_US_LIST
    );
  }, [APIService]);

  const getCountryList = useCallback(() => {
    return APIService?.get(REACT_APP_API_URL + API_URLS.API + API_URLS.COUNTRY);
  }, [APIService]);

  const validateBusinessUserInquaryForm = useCallback(
    (body: UserDetailInitialState) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_CLIENT + API_URLS.VALIDATE_INQUARY,
        body
      );
    },
    [APIService]
  );

  const AuthServiceOperations: AuthServiceOperations = useMemo(
    () => ({
      logIn,
      saveLoginDetailsInStore,
      resetPassword,
      forgetPassword,
      changePassword,
      validateToken,
      createPasswordOtp,
      createPassword,

      validateOtp,
      signUp,
      validateSingupForm,
      sendOtp,
      logout,
      GetHearAboutUsList,
      getCountryList,
      reactivate,
      corporateUserSignUp,
      validateBusinessUserInquaryForm,
    }),
    [
      logIn,
      saveLoginDetailsInStore,
      resetPassword,
      forgetPassword,
      changePassword,
      validateToken,
      createPasswordOtp,
      createPassword,
      validateOtp,
      signUp,
      validateSingupForm,
      sendOtp,
      logout,
      GetHearAboutUsList,
      getCountryList,
      reactivate,
      corporateUserSignUp,
      validateBusinessUserInquaryForm,
    ]
  );

  return (
    <AuthServiceContext.Provider value={AuthServiceOperations}>
      {props.children}
    </AuthServiceContext.Provider>
  );
};

export const useAuthService = () => {
  return useContext(AuthServiceContext);
};
