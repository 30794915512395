import { AxiosError } from "axios";
import { API_URLS } from "../../../core/models/enums/url.enums";
import { message } from "antd";
import { FieldData } from "rc-field-form/es/interface";
import axiosHttp from "../../../core/services/Interceptors";
import { COURSE_TYPE } from "../../MyCourses/models/enums/course.enum";
import { WorkForceTableDataType } from "../../WorkforceProfile/helper/constant";

const { REACT_APP_API_URL } = process.env;

export type GenericResponse<T> = {
  result: T;
  // status: number;
  // errors?: FieldData[];
  errorMessage?: string | FieldData[];
  message?: string;
  isError: boolean;
  isFieldLevel: boolean;
  statusCode: number;
};

export type ValidateUserBeforeTeamDetailPagePayload = {
  teamKey: string;
};

export const validateUserBeforeTeamDetailPage = async (
  params: ValidateUserBeforeTeamDetailPagePayload
) => {
  const response = await axiosHttp.post<GenericResponse<boolean>>(
    REACT_APP_API_URL + API_URLS.VALIDATE_USER_BEFORE_FETCHING_TEAM_DETAIL_,
    params
  );

  return response.data;
};

export const validateManageTeamLink = async () => {
  try {
    const response = await axiosHttp.get(
      REACT_APP_API_URL + API_URLS.VALIDATE_MANAGE_TEAM_LINK_IN_HEADER
    );
    return response.data.result;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error.message);
    }
  }
};

export type memberIdArray = {
  memberId: number;
  isWhatsAppUser: boolean;
};

export type DeleteMemberPayload = {
  teamKey: string;
  memberIds: memberIdArray[];
};
export type DeleteMemberResponse = {
  message: string;
};
export const deleteMember = async (params: DeleteMemberPayload) => {
  const response = await axiosHttp.delete<
    GenericResponse<DeleteMemberResponse>
  >(REACT_APP_API_URL + API_URLS.REMOVE_TEAM_MEMBER, {
    data: params,
  });

  return response.data;
};

export type updateCourseDeadlinePayload = {
  teamKey: string;
  courseId: number;
  memberIds?: number[];
  assignedDeadLine: string;
};

export const updateCourseDeadline = async (
  params: updateCourseDeadlinePayload
) => {
  const response = await axiosHttp.put<GenericResponse<number>>(
    REACT_APP_API_URL + API_URLS.UPDATE_COURSE_DEADLINE,
    params
  );

  return response.data;
};

export type GetAssignedCoursesListOfTeamMemberType = {
  teamKey?: string | null;
  memberId?: number;
  courseName?: string;
};

export type MyTeamsAssignedCourseType = {
  courseImage: string;
  deadLine: string;
  isStarted: boolean;
  name: string;
  score: string;
  status: string;
};

export type MyTeamsAssignedCoursesListTypeResponse = {
  courseLists: MyTeamsAssignedCourseType[];
};

export const GetAssignedCoursesListOfTeamMember = async (
  params: GetAssignedCoursesListOfTeamMemberType
) => {
  try {
    const response = await axiosHttp.get<
      GenericResponse<MyTeamsAssignedCoursesListTypeResponse>
    >(REACT_APP_API_URL + API_URLS.GET_ASSIGNED_COURSE_LIST, { params });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      message.error(error.message);
    }
  }
};

export const GetCoursesListOfMember = async (
  params: GetAssignedCoursesListOfTeamMemberType
) => {
  try {
    const response = await axiosHttp.get<
      GenericResponse<MyTeamsAssignedCoursesListTypeResponse>
    >(REACT_APP_API_URL + API_URLS.GET_COURSE_LIST_BY_MEMBER, { params });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      message.error(error.message);
    }
  }
};

export type TeamsAvailableCoursesRequestType = {
  courseName?: string | null;
  pageIndex?: number;
  pageSize?: number;
  sortingBy?: string;
};

export type TeamsAvailableCoursesType = {
  courseId: number;
  courseKey: string;
  courseName: string;
  courseOverview: string;
  author: string;
  courseImage: string;
  duration: string;
  avgRating: number;
  deliveryMethodId: number;
  courseType: string;
  courseDeadline: Date;
  courseFreeOrPaid: COURSE_TYPE;
  isWhatsAppCourse: boolean;
};

export type GetTeamsAvailableCoursesListResponse = {
  items: TeamsAvailableCoursesType[];
  totalCount: number;
};

export const GetTeamsAvailableCoursesList = async (
  params: TeamsAvailableCoursesRequestType
) => {
  try {
    const response = await axiosHttp.get<
      GenericResponse<GetTeamsAvailableCoursesListResponse>
    >(REACT_APP_API_URL + API_URLS.GET_TEAMS_AVAILABLE_COURSES, { params });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      message.error(error.message);
    }
  }
};

//
export type TeamsAvailableBundleRequestType = {
  bundleName?: string | null;
  pageIndex?: number;
  pageSize?: number;
  sortingBy?: string;
};

export type TeamsAvailableBundleType = {
  courseBundleId: number;
  courseBundleImage: string;
  courseBundleKey: string;
  courseBundleName: string;
  courseDuration: number;
  rate: number;
};

export type GetTeamsAvailableBundleListResponse = {
  items: TeamsAvailableBundleType[];
  totalCount: number;
};

export const GetTeamsAvailableBundleList = async (
  params: TeamsAvailableBundleRequestType
) => {
  try {
    const response = await axiosHttp.get<
      GenericResponse<GetTeamsAvailableBundleListResponse>
    >(REACT_APP_API_URL + API_URLS.GET_TEAMS_AVAILABLE_BUNDLE, { params });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      message.error(error.message);
    }
  }
};
//

export type GetTeamsAssignedCoursesListParamsType = {
  teamkey: string;
};

export type MembersCourseDetailsType = {
  userId: number;
  userProfilePicture: string;
  userName: string;
  deadLine: string;
  isStarted: boolean;
  status: string;
  score: string;
};
export type AssignedCourseType = {
  courseId: number;
  courseKey: string;
  courseName: string;
  deadLine: string;
  membersCourseDetails: MembersCourseDetailsType[];
  isWhatsAppCourse: boolean;
};

export type AssignedCoursesListType = {
  courseDetails: AssignedCourseType[];
};

export const GetTeamsAssignedCoursesList = async (
  params: GetTeamsAssignedCoursesListParamsType
) => {
  try {
    const response = await axiosHttp.get<
      GenericResponse<AssignedCoursesListType>
    >(REACT_APP_API_URL + API_URLS.GET_TEAMS_ASSIGNED_COURSES, { params });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      message.error(error.message);
    }
  }
};

export type GetTeamsMemberListParamsType = {
  pageIndex?: number;
  pageSize?: number;
  memberName?: string;
  teamKey?: string;
  sortingBy?: string;
};

export type TeamMembers = {
  role: string;
  jobTitle: string;
  teamMemberId: number;
  userName: string;
  profilePicture: string;
  isWhatsAppUser: boolean;
};

export type GetTeamsMemberResponse = {
  items: TeamMembers[];
  totalCount: number;
};

export const GetTeamsMemberList = async (
  params: GetTeamsMemberListParamsType
) => {
  try {
    const response = await axiosHttp.get<
      GenericResponse<GetTeamsMemberResponse>
    >(REACT_APP_API_URL + API_URLS.TEAM_OVERVIEW, { params });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      message.error(error.message);
    }
  }
};

export type GetTeamsByIdParamsType = {
  teamKey: string;
};

export type TeamMembersRoleType = {
  teamsRoleTypeId: number;
  teamRoleTypeKey: string;
  teamRoleTypeDescription: string;
};

export type GetTeamMembersRolesResponse = TeamMembersRoleType[];

export const getTeamMembersRoles = async () => {
  const response = await axiosHttp.get<
    GenericResponse<GetTeamMembersRolesResponse>
  >(
    REACT_APP_API_URL +
      API_URLS.BASE_TEAMS_MEMBER +
      API_URLS.GET_TEAM_MEMBERS_ROLES
  );

  return response.data.result;
};

export type GetTeamMembersPayload = {
  memberName?: string;
  pageIndex?: number;
  pageSize?: number;
};

export type TeamMembersType = {
  teamMemberId: number;
  userName: string;
  profilePicture: string;
  jobTitle: string;
  isWhatsAppUser: boolean;
};

export type GetTeamMembersResponse = {
  items: TeamMembersType[];
  totalCount: number;
};
export const getTeamMembers = async (params?: GetTeamMembersPayload) => {
  const response = await axiosHttp.get<GenericResponse<GetTeamMembersResponse>>(
    REACT_APP_API_URL + API_URLS.BASE_TEAMS + API_URLS.GET_TEAM_MEMBERS,
    {
      params,
    }
  );

  return response.data.result;
};

export type TeamMemberRolesType = {
  memberId: number;
  teamRoleTypeId: number;
  isDeleted?: boolean;
  isWhatsAppUser?: boolean;
  accessType?: string;
};

export type CreateEditTeamPayload = {
  teamKey?: string;
  teamName: string;
  teamTypeId?: number;
  teamMembers?: TeamMemberRolesType[];
};

export const createTeam = async (params: CreateEditTeamPayload) => {
  const response = await axiosHttp.post<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.BASE_TEAMS + API_URLS.CREATE,
    params
  );

  return response.data;
};

export const updateTeam = async (params: CreateEditTeamPayload) => {
  const response = await axiosHttp.put<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.BASE_TEAMS + API_URLS.UPDATE,
    params
  );
  return response.data;
};

export type GetTeamsPayload = {
  teamName?: string;
  pageIndex?: number;
  pageSize?: number;
  isMyTeam: boolean;
};
export type Teams = {
  team: string;
  name: string;
  owner: string;
  manager: number;
  teamLeader: number;
  totalMembers: number;
  teamKey: string;
};

export type GetTeamsResponse = {
  items: Teams[];
  totalCount: number;
};
export const getTeams = async (params: GetTeamsPayload) => {
  const response = await axiosHttp.get<GenericResponse<GetTeamsResponse>>(
    REACT_APP_API_URL + API_URLS.BASE_TEAMS + API_URLS.GET_TEAMS,
    {
      params,
    }
  );
  return response.data;
};

export type GetTeamByIdPayload = {
  teamKey: string;
};

export type GetTeamByIdResponse = {
  teamId: number;
  teamKey: string;
  teamName: string;
  teamTypeId: number;
  teamMembers: TeamMemberRolesType[];
  clientId: string;
};
export const getTeamById = async (params: GetTeamByIdPayload) => {
  const response = await axiosHttp.get<GenericResponse<GetTeamByIdResponse>>(
    REACT_APP_API_URL + API_URLS.BASE_TEAMS + API_URLS.GET_TEAM_BY_ID,
    {
      params,
    }
  );

  return response.data;
};

export type DeleteTeamPayload = {
  teamKey: string;
};
export const deleteTeam = async (params: DeleteTeamPayload) => {
  const response = await axiosHttp.delete<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.BASE_TEAMS + API_URLS.DELETE,
    {
      params,
    }
  );

  return response.data;
};

export type GetTeamMembersToAssignPayload = {
  teamKey: string;
  courseId: number;
  accessType: number;
  IsWhatAppCourse: boolean;
};
export type TeamMembersToAssign = {
  userId: number;
  userName: string;
  jobTitle: string;
  isWhatsAppUser: boolean;
  isChecked?: boolean; // only FE use not coming from BE
};
export type GetTeamMembersToAssignResponse = TeamMembersToAssign[];

export const getTeamMembersToAssign = async (
  params: GetTeamMembersToAssignPayload
) => {
  const response = await axiosHttp.get<
    GenericResponse<GetTeamMembersToAssignResponse>
  >(
    REACT_APP_API_URL +
      API_URLS.BASE_TEAMS_MEMBER +
      API_URLS.GET_TEAM_MEMBERS_TO_ASSIGN,
    {
      params,
    }
  );

  return response.data;
};

export type GetTeamMembersToAssignBundlePayload = {
  teamKey: string;
  courseBundleId: number;
};
export type TeamMembersToAssignBundle = {
  userId: number;
  userName: string;
  jobTitle: string;
};
export type GetTeamMembersToAssignBundleResponse = TeamMembersToAssignBundle[];

export const getTeamMembersToAssignBundle = async (
  params: GetTeamMembersToAssignBundlePayload
) => {
  const response = await axiosHttp.get<
    GenericResponse<GetTeamMembersToAssignBundleResponse>
  >(
    REACT_APP_API_URL +
      API_URLS.BASE_TEAMS_MEMBER +
      API_URLS.GET_TEAM_MEMBERS_TO_ASSIGN_BUNDLE,
    { params }
  );

  return response.data;
};

export type AssignCoursePayload = {
  teamKey: string;
  courseId: number;
  isWhatsAppCourse: boolean;
  memberIds: number[];
};
export const assignCourse = async (params: AssignCoursePayload) => {
  const response = await axiosHttp.post<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.BASE_TEAMS + API_URLS.ASSIGN_COURSE,
    params
  );

  return response.data;
};

export type AssignBundlePayload = {
  teamKey: string;
  courseBundleId: number;
  memberIds: number[];
  courseDeadline: string;
};
export const assignBundle = async (params: AssignBundlePayload) => {
  const response = await axiosHttp.post<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.BASE_TEAMS + API_URLS.ASSIGN_COURSE_BUNDLE,
    params
  );

  return response.data;
};

export type UnAssignCoursePayload = {
  teamKey: string;
  courseId: number;
  isWhatsAppCourse: boolean;
  memberIds: number[];
};

export const unAssignCourse = async (params: UnAssignCoursePayload) => {
  const response = await axiosHttp.post<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.UNASSIGN_COURSE,
    params
  );

  return response.data;
};

export type GetTeamMemberRolesPermissionPayload = {
  teamKey: string;
};
export type GetTeamMemberRolesPermissionResponse = {
  teamRoleTypeId: number;
  teamRoleTypeDescription: string;
  canCreateTeam: boolean;
  canAssignCourse: boolean;
  canAddMember: boolean;
  canExtendDeadline: boolean;
  canSendMessage: boolean;
  canUpdateMemberRole: boolean;
  canDeleteMember: boolean;
  canRemoveAllMembers: boolean;
  canExtendSelfTimeline: boolean;
};
export const getTeamMemberRolesPermission = async (
  params: GetTeamMemberRolesPermissionPayload
) => {
  const response = await axiosHttp.get<
    GenericResponse<GetTeamMemberRolesPermissionResponse>
  >(
    REACT_APP_API_URL +
      API_URLS.BASE_TEAMS_MEMBER +
      API_URLS.GET_TEAM_MEMBER_ROLES_PERMISSION,
    { params }
  );

  return response.data;
};

export type GetUsersForNonTeamMembersPayload = {
  teamKey: string;
};

export type GetUsersForTransferTeamsPayload = {
  teamKey: string;
};

export type UsersForNonTeamMembers = {
  userId: number;
  userName: string;
  jobTitle: string;
  isWhatsAppUser: boolean;
};

export const getUsersForNonTeamMembers = async (
  params: GetUsersForNonTeamMembersPayload
) => {
  const response = await axiosHttp.get<
    GenericResponse<UsersForNonTeamMembers[]>
  >(
    REACT_APP_API_URL +
      API_URLS.BASE_TEAMS +
      API_URLS.GET_USERS_FOR_NON_TEAM_MEMBERS,
    { params }
  );

  return response.data;
};

export type UsersForTransferTeams = {
  profilePicture: string;
  userId: number;
  userName: string;
  jobTitle: string;
};

export const getUsersForTransferTeams = async (
  params: GetUsersForNonTeamMembersPayload
) => {
  const response = await axiosHttp.get<
    GenericResponse<UsersForTransferTeams[]>
  >(
    REACT_APP_API_URL +
      API_URLS.BASE_TEAMS +
      API_URLS.GET_USERS_FOR_TRANSFER_TEAMS,
    { params }
  );

  return response.data;
};

export type TransferTeamOwnerShip = {
  teamKey: string;
  userId: number;
};

export const transferTeamOwnerShip = async (params: TransferTeamOwnerShip) => {
  const response = await axiosHttp.put<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.TRANSFER,
    params
  );

  return response.data;
};

export interface IExtendDeadLineRequest {
  teamKey: string;
  courseName: string;
  assignedDeadLine: string;
}

export const extendDeadLineRequest = async (params: IExtendDeadLineRequest) => {
  const response = await axiosHttp.post<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.EXTEND_DEADLINE_REQUEST,
    params
  );

  return response.data;
};

export interface ISendMessageToLeads {
  teamKey: string;
  message: string;
}

export const sendMessageToLeads = async (params: ISendMessageToLeads) => {
  const response = await axiosHttp.post<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.SEND_MESSAGE_TO_LEADS,
    params
  );

  return response.data;
};

export interface ISendMessageToMembers {
  teamKey: string;
  message: string;
  memberIds: number[];
}

export const sendMessageToMembers = async (params: ISendMessageToMembers) => {
  const response = await axiosHttp.post<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.SEND_MESSAGE_TO_MEMBERS,
    params
  );

  return response.data;
};

// Get workforce Table list
export type GetTeamWorkForceListParamsType = {
  pageIndex: number;
  pageSize: number;
  search?: Partial<Record<keyof WorkForceTableDataType, string>>;
  sortBy?: string;
  sortDirection?: string;
};

export type GetTeamWorkForceListResponseType = {
  workForceUsers: WorkForceTableDataType[];
  totalCount: number;
};

export const GetTeamWorkForceList = async (
  params: GetTeamWorkForceListParamsType
) => {
  const updatedParams = {
    ...params,
    search: params.search ?? {},
  };
  const response = await axiosHttp.post<
    GenericResponse<GetTeamWorkForceListResponseType | null>
  >(
    REACT_APP_API_URL +
      API_URLS.BASE_TEAMS +
      API_URLS.GET_WORKFORCE_USERS_FOR_TEAM,
    updatedParams
  );
  return response.data.result;
};

export interface EditTeamMemberPayload {
  memberId: number;
  teamRoleTypeId: number;
  isDeleted: boolean;
  teamKey: string;
}

export const updateTeamMember = async (params: EditTeamMemberPayload) => {
  const response = await axiosHttp.put<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.BASE_TEAMS_MEMBER + API_URLS.UPDATE,
    params
  );
  return response.data;
};

export interface AddTeamMemberPayload {
  teamKey?: string;
  teamMembersToAdd: TeamMembersToAddType[];
}

export type TeamMembersToAddType = {
  memberId: number;
  teamRoleTypeId: number;
  IsWhatsAppUser: boolean;
};

export const addTeamMember = async (params: AddTeamMemberPayload) => {
  const response = await axiosHttp.post<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.ADD_MEMBER_IN_TEAM,
    params
  );
  return response.data;
};

export type TeamReportRequestType = {
  teamKey: string;
};
export type TeamReportResponseType = {
  teamReportExcel: string;
};
export const teamReport = async (params: TeamReportRequestType) => {
  const response = await axiosHttp.get<GenericResponse<TeamReportResponseType>>(
    REACT_APP_API_URL + API_URLS.BASE_TEAMS + API_URLS.TEAM_REPORT,
    { params }
  );
  return response.data;
};
