import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AcademyData {
  academyId: number;
  academyKey: string;
  academyName: string;
  description: string | null;
  academyMasterId: number;
}

const initialAcademyState: AcademyData = {
  academyId: 0,
  academyKey: "",
  academyName: "",
  description: "",
  academyMasterId: 0,
};

export const academySlice = createSlice({
  name: "academy",
  initialState: initialAcademyState,
  reducers: {
    setAcademyState: (state, action: PayloadAction<AcademyData>) => {
      
      state.academyId = action.payload.academyId;
      state.academyKey = action.payload.academyKey;
      state.academyName = action.payload.academyName;
      state.description = action.payload.description;
      state.academyMasterId = action.payload.academyMasterId;
    },
  },
});

export const { setAcademyState } = academySlice.actions;
export default academySlice.reducer;
