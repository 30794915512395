import { createContext, useCallback, useContext, useMemo } from "react";

import { ComponentProps } from "../../../core/models/interfaces/component";
import {
  ApiOperations,
  useAPIService,
} from "../../../core/services/API.service";
import { API_URLS } from "../../../core/models/enums/url.enums";
import {
  UpdatePersonalDeadline,
  updateRating,
} from "../models/types/course.type";
import { COURSE_SEGEMENT_TYPE } from "../models/enums/course.enum";
import { useDispatch } from "react-redux";
import {
  setWishlistCountState,
  setCartlistCountState,
} from "../../../../store/slice/CartDetails";

const { REACT_APP_API_URL } = process.env;

export interface CourseServiceOperations {
  getAvailableCourses: Function;
  getAvailableAssessments: Function;
  getMyCourses: Function;
  getMyELearning: Function;
  getMyAssessments: Function;
  extendCourseExpiry: Function;
  abandonCourse: Function;
  updateRating: Function;
  updatePersonalDeadline: Function;
  GetCourseDetail: Function;
  saveCourse: Function;
  navigteToStartCourseHandler: Function;
  UpdateUserWishList: Function;
  getTagListFilter: Function;
  getTypeListFilter: Function;
  getLabelListFilter: Function;
  getDurationRangeFilter: Function;
  getPriceRangeFilter: Function;
  getPriceFilter: Function;
  getCourses: Function;
  getWishlistCoursesList: Function;
  getWishListCount: Function;
  getCoursesFromBundle: Function;
  downloadCertificate: Function;
  getCartDetails: Function;
  addToCart: Function;
  deleteItem: Function;
  clearCart: Function;
  getCartData: Function;
  selectCartItem: Function;
  getCartCount: Function;
  makePaymentTransaction: Function;
  saveTransactionDetail: Function;
}
const CourseServiceContext = createContext<CourseServiceOperations | null>(
  null
);

export const CourseServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();
  const dispatch = useDispatch();

  const getWishlistCoursesList = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_WISHLIST +
        "?timestamp=" +
        new Date().getTime()
    );
  }, [APIService]);

  const getWishListCount = useCallback(() => {
    getWishlistCoursesList().then((response: any) => {
      if (response?.isError) {
        dispatch(setWishlistCountState(0));
      } else if (response?.result && response?.result.length > 0) {
        dispatch(setWishlistCountState(response.result.length));
      } else {
        dispatch(setWishlistCountState(0));
      }
    });
  }, [dispatch, getWishlistCoursesList]);

  const getAvailableCourses = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_AVAILABLE_COURSES +
        "?isAssessment=false" +
        "&timestamp=" +
        new Date().getTime()
    );
  }, [APIService]);

  const getAvailableAssessments = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_AVAILABLE_COURSES +
        "?isAssessment=true" +
        "&timestamp=" +
        new Date().getTime()
    );
  }, [APIService]);

  const getMyCourses = useCallback(
    (courseStatusType: string) => {
      return APIService?.get(
        REACT_APP_API_URL +
          API_URLS.BASE_COURSE +
          API_URLS.GET_MY_COURSES +
          courseStatusType +
          "&isAssessment=false" +
          "&timestamp=" +
          new Date().getTime()
      );
    },
    [APIService]
  );

  const getMyELearning = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_E_LEARNING +
        "?timestamp=" +
        new Date().getTime()
    );
  }, [APIService]);

  const getMyAssessments = useCallback(
    (courseStatusType: string) => {
      return APIService?.get(
        REACT_APP_API_URL +
          API_URLS.BASE_COURSE +
          API_URLS.GET_MY_COURSES +
          courseStatusType +
          "&isAssessment=true" +
          "&timestamp=" +
          new Date().getTime()
      );
    },
    [APIService]
  );

  const GetCourseDetail = useCallback(
    (id: string) => {
      return APIService?.get(
        REACT_APP_API_URL +
          API_URLS.BASE_COURSE +
          API_URLS.GET_COURSE_DETAIL +
          id +
          "&timestamp=" +
          new Date().getTime()
      );
    },
    [APIService]
  );

  const UpdateUserWishList = useCallback(
    (courseId: number, type: COURSE_SEGEMENT_TYPE) => {
      return APIService?.post(
        REACT_APP_API_URL +
          API_URLS.BASE_COURSE +
          API_URLS.UPDATE_WISHLIST +
          courseId +
          "&type=" +
          type
      );
    },
    [APIService]
  );

  const extendCourseExpiry = useCallback(
    (userCourseKey: string) => {
      return APIService?.post(
        REACT_APP_API_URL +
          API_URLS.BASE_COURSE +
          API_URLS.EXTEND_COURSE_EXPIRY +
          userCourseKey
      );
    },
    [APIService]
  );

  const abandonCourse = useCallback(
    (userCourseKey: string) => {
      return APIService?.post(
        REACT_APP_API_URL +
          API_URLS.BASE_COURSE +
          API_URLS.ABANDON_COURSE +
          userCourseKey
      );
    },
    [APIService]
  );

  const updateRating = useCallback(
    (body: updateRating) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.UPDATE_RATING,
        body
      );
    },
    [APIService]
  );

  const updatePersonalDeadline = useCallback(
    (body: UpdatePersonalDeadline) => {
      return APIService?.post(
        REACT_APP_API_URL +
          API_URLS.BASE_COURSE +
          API_URLS.UPDATE_PERSONAL_DEADLINE,
        body
      );
    },
    [APIService]
  );

  const saveCourse = useCallback(
    (body: any) => {
      return APIService?.post(
        REACT_APP_API_URL +
          API_URLS.BASE_COURSE +
          API_URLS.ADD_USER_COURSE_LIST,
        body
      );
    },
    [APIService]
  );

  const navigteToStartCourseHandler = useCallback(
    (courseId: number) => {
      return APIService?.get(
        REACT_APP_API_URL +
          API_URLS.BASE_COURSE +
          API_URLS.START_COURSE_NAVIGATE_TO_MOODLE +
          courseId
      );
    },
    [APIService]
  );

  const getTagListFilter = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_TAG_LIST_FILTER
    );
  }, [APIService]);

  const getTypeListFilter = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_TYPE_LIST_FILTER
    );
  }, [APIService]);

  const getLabelListFilter = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_LEVEL_LIST_FILTER
    );
  }, [APIService]);

  const getPriceFilter = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_PRICE_LIST_FILTER
    );
  }, [APIService]);

  const getDurationRangeFilter = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_DURATION_RANGE_FILTER
    );
  }, [APIService]);

  const getPriceRangeFilter = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_PRICE_RANGE_FILTER
    );
  }, [APIService]);

  const getCourses = useCallback(
    (queryparam: any) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_STORE_DATA,
        queryparam
      );
    },
    [APIService]
  );

  const getCoursesFromBundle = useCallback(
    (id: any) => {
      return APIService?.get(
        REACT_APP_API_URL +
          API_URLS.BASE_COURSE +
          API_URLS.GET_COURSES_FROM_BUNDLE +
          `?courseBundleKey=${id}` +
          "&timestamp=" +
          new Date().getTime()
      );
    },
    [APIService]
  );

  const downloadCertificate = useCallback(
    (courseId: number) => {
      return APIService?.get(
        REACT_APP_API_URL +
          API_URLS.BASE_COURSE +
          API_URLS.GET_CERTIFICATE +
          "?courseId=" +
          courseId
      );
    },
    [APIService]
  );

  // Shopping cart
  const getCartDetails = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_SHOPPING +
        API_URLS.GET_SHOPPING_CART +
        "?timestamp=" +
        new Date().getTime()
    );
  }, [APIService]);

  const getCartCount = useCallback(() => {
    getCartDetails().then((response: any) => {
      if (response?.isError) {
        dispatch(setCartlistCountState(0));
      } else if (response?.result && response?.result.length > 0) {
        dispatch(setCartlistCountState(response.result.length));
      } else {
        dispatch(setCartlistCountState(0));
      }
    });
  }, [dispatch, getCartDetails]);

  const addToCart = useCallback(
    (payload: any) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_SHOPPING + API_URLS.ADD_TO_CART,
        payload
      );
    },
    [APIService]
  );

  const deleteItem = useCallback(
    (id: number) => {
      return APIService?.remove(
        REACT_APP_API_URL + API_URLS.BASE_SHOPPING + API_URLS.DELETE_ITEM + id
      );
    },
    [APIService]
  );

  const clearCart = useCallback(() => {
    return APIService?.remove(
      REACT_APP_API_URL + API_URLS.BASE_SHOPPING + API_URLS.CLEAR_CART
    );
  }, [APIService]);

  const getCartData = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_SHOPPING + API_URLS.GET_CART_DETAILS
    );
  }, [APIService]);

  const selectCartItem = useCallback(
    (id: string[]) => {
      return APIService?.post(
        REACT_APP_API_URL + API_URLS.BASE_SHOPPING + API_URLS.SELECT_CART_ITEM,
        id
      );
    },
    [APIService]
  );

  const makePaymentTransaction = useCallback(
    (payload: any) => {
      return APIService?.post(
        REACT_APP_API_URL +
          API_URLS.BASE_PAYMENT +
          API_URLS.MAKE_PAYMENT_TRANSACTION,
        payload
      );
    },
    [APIService]
  );

  const saveTransactionDetail = useCallback(
    (payload: any) => {
      return APIService?.post(
        REACT_APP_API_URL +
          API_URLS.BASE_PAYMENT +
          API_URLS.SAVE_TRANSECTION_DETAIL,
        payload
      );
    },
    [APIService]
  );

  const CourseServiceOperations: CourseServiceOperations = useMemo(
    () => ({
      getAvailableCourses,
      getAvailableAssessments,
      getMyCourses,
      getMyELearning,
      getMyAssessments,
      GetCourseDetail,
      saveCourse,
      abandonCourse,
      extendCourseExpiry,
      updateRating,
      updatePersonalDeadline,
      navigteToStartCourseHandler,
      UpdateUserWishList,
      getTagListFilter,
      getTypeListFilter,
      getLabelListFilter,
      getDurationRangeFilter,
      getPriceRangeFilter,
      getPriceFilter,
      getCourses,
      getWishlistCoursesList,
      getWishListCount,
      getCoursesFromBundle,
      downloadCertificate,
      getCartDetails,
      addToCart,
      deleteItem,
      clearCart,
      getCartData,
      selectCartItem,
      getCartCount,
      makePaymentTransaction,
      saveTransactionDetail,
    }),
    [
      getAvailableCourses,
      getAvailableAssessments,
      getMyCourses,
      getMyELearning,
      getMyAssessments,
      GetCourseDetail,
      saveCourse,
      abandonCourse,
      extendCourseExpiry,
      updateRating,
      updatePersonalDeadline,
      navigteToStartCourseHandler,
      UpdateUserWishList,
      getTagListFilter,
      getTypeListFilter,
      getLabelListFilter,
      getDurationRangeFilter,
      getPriceRangeFilter,
      getPriceFilter,
      getCourses,
      getWishlistCoursesList,
      getWishListCount,
      getCoursesFromBundle,
      downloadCertificate,
      getCartDetails,
      addToCart,
      deleteItem,
      clearCart,
      getCartData,
      selectCartItem,
      getCartCount,
      makePaymentTransaction,
      saveTransactionDetail,
    ]
  );

  // const CourseServiceOperations: CourseServiceOperations = {
  //   getAvailableCourses,
  //   getAvailableAssessments,
  //   getMyCourses,
  //   getMyELearning,
  //   getMyAssessments,
  //   GetCourseDetail,
  //   saveCourse,
  //   abandonCourse,
  //   extendCourseExpiry,
  //   updateRating,
  //   updatePersonalDeadline,
  //   navigteToStartCourseHandler,
  //   UpdateUserWishList,
  //   getTagListFilter,
  //   getTypeListFilter,
  //   getLabelListFilter,
  //   getDurationRangeFilter,
  //   getPriceRangeFilter,
  //   getPriceFilter,
  //   getCourses,
  //   getWishlistCoursesList,
  //   getWishListCount,
  //   getCoursesFromBundle,
  //   downloadCertificate,
  //   getCartDetails,
  //   addToCart,
  //   deleteItem,
  //   clearCart,
  //   getCartData,
  //   selectCartItem,
  //   getCartCount,
  //   makePaymentTransaction,
  //   saveTransactionDetail,
  // };

  return (
    <CourseServiceContext.Provider value={CourseServiceOperations}>
      {props.children}
    </CourseServiceContext.Provider>
  );
};

export const useCourseService = () => {
  return useContext(CourseServiceContext);
};
