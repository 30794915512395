import { createContext, useCallback, useContext, useMemo } from "react";
import { ComponentProps } from "../models/interfaces/component";

import axiosHttp from "./Interceptors";

export interface ApiOperations {
  auth: Function;
  get: Function;
  post: Function;
  put: Function;
  remove: Function;
}

const APIServiceContext = createContext<ApiOperations | null>(null);

export const APIServiceProvider = (props: ComponentProps) => {
  const auth = useCallback((url: string, body: any) => {
    return axiosHttp
      .post(url, body)
      .then(function (response) {
        const elseStatement = response?.data
          ? response?.data.result
          : response?.data;
        return response ? elseStatement : response;
      })
      .catch(function (error) {
        const elseStatementError = error.response
          ? error.response?.data
          : error.response;
        return error ? elseStatementError : error;
      });
  }, []);

  const get = useCallback((url: string) => {
    return axiosHttp
      .get(url)
      .then(function (response: any) {
        return Promise.resolve(response ? response?.data : response);
      })
      .catch(function (error) {
        const elseStatementError = error.response
          ? error.response?.data
          : error.response;
        return error ? elseStatementError : error;
      });
  }, []);

  const post = useCallback((url: string, body: any) => {
    return axiosHttp
      .post(url, body)
      .then(function (response) {
        return Promise.resolve(response ? response?.data : response);
      })
      .catch(function (error) {
        const elseStatementError = error.response
          ? error.response?.data
          : error.response;
        return error ? elseStatementError : error;
      });
  }, []);

  const put = useCallback((url: string, body: any) => {
    return axiosHttp
      .put(url, body)
      .then(function (response) {
        return Promise.resolve(response ? response?.data : response);
      })
      .catch(function (error) {
        const elseStatementError = error.response
          ? error.response?.data
          : error.response;
        return error ? elseStatementError : error;
      });
  }, []);

  const remove = useCallback((url: string, body?: any) => {
    return axiosHttp
      .delete(url, { data: body })
      .then(function (response) {
        return Promise.resolve(response ? response?.data : response);
      })
      .catch(function (error) {
        const elseStatementError = error.response
          ? error.response?.data
          : error.response;
        return error ? elseStatementError : error;
      });
  }, []);

  const ApiOperations: ApiOperations = useMemo(
    () => ({
      auth,
      get,
      post,
      put,
      remove,
    }),
    [auth, get, post, put, remove]
  );

  return (
    <APIServiceContext.Provider value={ApiOperations}>
      {props.children}
    </APIServiceContext.Provider>
  );
};

export const useAPIService = () => {
  return useContext(APIServiceContext);
};
