import * as React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { ComponentProps } from "../../models/interfaces/component";

export type LoaderType = ComponentProps & {
  styleLoadingOutlined?: React.CSSProperties;
};

const Loader: React.FC<LoaderType> = (props: LoaderType) => {
  return (
    <div className="flex justify-center items-center h-full">
      <Spin
        indicator={
          <LoadingOutlined
            style={props.styleLoadingOutlined}
            spin
            className="text-primary"
          />
        }
      />
    </div>
  );
};

export default Loader;
